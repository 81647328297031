<template>
    <div class="space">
        <div class="background">
            <img :src="data.background" />
            <div class="space-navigation client-content-padding">
                <img :src="data.logo" />
                <div class="info">
                    <h5>
                        {{ data.company.companyName }}
                    </h5>
                    <div class="links">
                        <a
                            v-for="(link, index) in navigationLinks"
                            :href="`/@${data.spaceSlug}/${link.tabName}`"
                            :class="{link: true, active: link.tabName == data.currentTab ? true : false, ['no-focus-outline']: true}"
                            :key="`space-nav-${index}`"
                        >
                            <span>{{ link.label }}</span>
                            <div v-if="index + 1 != navigationLinks.length" class="line"></div>
                        </a>
                    </div> 
                </div>
                <div class="mobile-links">
                    <a
                        v-for="(link, index) in navigationLinks"
                        :href="`/@${data.spaceSlug}/${link.tabName}`"
                        :class="{link: true, active: link.tabName == data.currentTab ? true : false, ['no-focus-outline']: true}"
                        :key="`space-nav-${index}`"
                        @click="data.currentTab = link.tabName"
                    >
                        <span>{{ link.label }}</span>
                        <div v-if="index + 1 != navigationLinks.length" class="line"></div>
                    </a>
                </div> 
            </div>
        </div>
        <div class="space-content">
            <component :is="tabComponent" :labels="labels" :data="dataToShow"></component>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Space',
    components: {
        SpaceAuthors: () => import('./SpaceAuthors.vue'),
        SpaceBundles: () => import('./SpaceBundles.vue'),
        SpaceHome: () => import('./SpaceHome.vue'),
        SpaceReviews: () => import('./SpaceReviews.vue')
    },
    props: {
        data: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            labels: {
                home: 'Home',
                courses: 'Courses',
                authors: 'Authors',
                reviews: 'Reviews',
                featured_courses: 'Featured courses',
                start_course: 'Start course'
            }
        }
    },
    computed: {
        navigationLinks() {
            return [
                {
                    tabName: 'home',
                    params: {
                        spaceId: 'spaceid'
                    },
                    label: this.labels.home
                },
                {
                    tabName: 'bundles',
                    params: {
                        spaceId: 'spaceid'
                    },
                    label: this.labels.courses
                },
                {
                    tabName: 'authors',
                    params: {
                        spaceId: 'spaceid'
                    },
                    label: this.labels.authors
                },
                {
                    tabName: 'reviews',
                    params: {
                        spaceId: 'spaceid'
                    },
                    label: this.labels.reviews
                }
            ];
        },

        dataToShow() {
            let result = {...JSON.parse(JSON.stringify(this.data))};

            return result;
        },
        tabComponent() {
            if (!this.data.currentTab?.length || this.data.currentTab == 'home') return 'SpaceHome';

            let result = 'SpaceHome';

            switch (this.data.currentTab) {
                case 'bundles':
                    result = 'SpaceBundles';
                    break;
                case 'authors':
                    result = 'SpaceAuthors';
                    break;
                case 'reviews':
                    result = 'SpaceReviews';
                    break;
            }

            return result;
        }
    },
    mounted() {
        console.log('Spaces ui loaded...', this.data);
        
    }
}
</script>

<style lang="scss" scoped>
@import '../styles/spaces_common.scss';

@import "../../../shared-files/shared.scss";

$space_nav_height: 120px;

.space {
    background-image: url('~../assets/BACKGRAY-1.jpg');
    background-repeat: repeat;
    background-color: #fff !important;

    .background {
        position: relative;
        width: 100%;

        img:not(.logo) {
            width: 100%;
            object-fit: cover;
            height: 40vh;
            object-position: center;
        }

        .space-navigation {
            left: 0;
            bottom: calc(#{$space_nav_height} / 2 * -1); /* Nav height var divided by half to achive navigation being overlaying bottom content by half of it's height */
            margin: auto;
            z-index: 2;
            position: absolute;
            display: flex;
            align-items: center;

            img {
                width: 120px;
                height: $space_nav_height; /* Nav height var */
                object-fit: contain;
                object-position: center;
                border-radius: 5px;
            }

            .info {
                margin-left: 2rem;

                h5 {
                    margin-top: 1rem;
                }

                .links {
                    display: flex;
                    padding-top: 1rem;

                    a {
                        display: flex;

                        span {
                            transition-property: color, background-color;
                            transition-duration: 300ms;
                            transition-timing-function: linear;
                            padding: 5px 15px;
                            border-radius: 5px;
                            background-color: rgba(0,0,0,0.03);
                            color: $focus_color;
                        }

                        div.line {
                            width: 1px;
                            height: 100%;
                            background-color:rgba(0,0,0,0.1);
                            margin-left: 15px;
                            margin-right: 15px;
                        }

                        &:last-child {
                            border-right: 0px;
                        }

                        &.active,
                        &:hover {
                            text-decoration: none;

                            span {
                                background-color: $focus_color;
                                color: $color_over_focus;
                                text-decoration: none;
                            }
                        }
                    }
                }
            }

            .mobile-links {
                display: none;
            }

            @media (max-width: 767.98px) {
                width: 100%;
                flex-wrap: wrap;
                bottom: calc(310px / 2 * -1);

                &.client-content-padding {
                    padding: 15px;
                }

                .mobile-links {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    margin-top: 20px;
                    background: #f3f3f3;
                    padding: 10px 0px;
                    
                    a {
                        display: flex;

                        span {
                            transition-property: color, background-color;
                            transition-duration: 300ms;
                            transition-timing-function: linear;
                            padding: 5px 15px;
                            border-radius: 5px;
                            background-color: rgba(0,0,0,0.03);
                            color: $focus_color;
                        }

                        div.line {
                            width: 1px;
                            height: 100%;
                            background-color:rgba(0,0,0,0.1);
                            margin-left: 15px;
                            margin-right: 15px;
                        }

                        &:last-child {
                            border-right: 0px;
                        }

                        &.active,
                        &:hover {
                            text-decoration: none;

                            span {
                                background-color: $focus_color;
                                color: $color_over_focus;
                                text-decoration: none;
                            }
                        }

                        &:first-of-type {
                            display: none;
                        }
                    }
                }

                .info {
                    h5 {
                        margin-top: 0;
                        margin-bottom: 0;
                    }

                    .links {
                        a {
                            .line {
                                display: none;
                            }
                        }

                        .link:not(:first-of-type) {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .space-content {
        min-height: calc(100vh - #{$space_nav_height} - (#{$space_nav_height} / 2 )); /* We want always minmum space so that on any screen overlay nav would never go over footer so it is 100vh minus nav height var and minus half of nav height var (to count it's height) */
        padding-top: 2rem;

        // Not needed in current ssr version
        // @include client-margin;
    }
}
</style>